class FormValidator {

  constructor(formEl) {
    this.formEl = formEl;
    this.formEl.noValidate = true;

    this.formEl.addEventListener('submit', (e) => {
      e.preventDefault();
    });
  }

  partIsValid(part, role = null) {
    let hasErrors = false;
    let firstErrorEl = null;
    let inputs = [];
    if (role) {
      inputs = Array.from(this.formEl.querySelectorAll(`[data-part="${part}"][data-role="${role}"] input`));
    } else {
      inputs = Array.from(this.formEl.querySelectorAll(`[data-part="${part}"] input`));
    };

    inputs.forEach((inputEl) => {
      if (!inputEl.validity.valid) {
        hasErrors = true;
        if (!firstErrorEl) {
          firstErrorEl = inputEl;
        }
        if (inputEl.classList.contains('input__element')) {
          inputEl.closest('.input').classList.add('input--invalid');
        } else if (inputEl.parentNode.classList.contains('likert_questions__question_scale')) {
          inputEl.closest('.likert_questions__question').classList.add('likert_questions__question--invalid');
        }
        inputEl.addEventListener('input', this.inputChangeHandler);
        inputEl.addEventListener('propertychange', this.inputChangeHandler);
      }
    });

    return {
      hasErrors: hasErrors,
      errorPosition: (firstErrorEl) ? window.scrollY + firstErrorEl.getBoundingClientRect().top : 0
    };
  }

  inputChangeHandler(e) {
    const el = e.currentTarget;
    if (el.validity.valid) {
      if (el.classList.contains('input__element')) {
        el.closest('.input').classList.remove('input--invalid');
      } else if (el.parentNode.classList.contains('likert_questions__question_scale')) {
        el.closest('.likert_questions__question').classList.remove('likert_questions__question--invalid');
      }
      el.removeEventListener('input', this.inputChangeHandler);
      el.removeEventListener('propertychange', this.inputChangeHandler);
    }
  }

}


export default FormValidator;
