import Parallaxr from './Parallaxr';
import ReportSelection from './ReportSelection';
import ScoreTable from './ScoreTable';
import Test from './Test';

class App {

  constructor() {
    this.currentPart = 'identification';
  }

  init() {
    const parallaxr = new Parallaxr();
    const test = new Test(this.currentPart);

    const scoreTables = [];
    Array.from(document.querySelectorAll('[data-score-table]')).forEach((el) => {
      scoreTables.push(new ScoreTable(el));
    });

    const reportSelection = new ReportSelection();
  }

}

export default App;
