class Parallaxr {

  constructor() {
    const parallaxContainer = document.querySelector('[data-parallax-container]');
    const parallaxEl1 = document.querySelector('[data-parallax-element-1]');
    const parallaxEl2 = document.querySelector('[data-parallax-element-2]');

    if (parallaxContainer && parallaxEl1 && parallaxEl2) {
      parallaxContainer.addEventListener('mousemove', (e) => {
      	this.moveElement(e, parallaxEl1, -50, parallaxContainer);
      	this.moveElement(e, parallaxEl2, -30, parallaxContainer);
      });
    } else {
      return false;
    }
  }

  moveElement(e, target, movement, container) {
    const containerOffset = this.getOffset(container);
    const relX = e.pageX - containerOffset.left;
    const relY = e.pageY - containerOffset.top;

    const x = (relX - container.getBoundingClientRect().width / 2) / container.getBoundingClientRect().width * movement;
    const y = (relY - container.getBoundingClientRect().height / 2) / container.getBoundingClientRect().height * movement;

    target.style.transform = `translate3d(${x}px, ${y}px, 0)`;
  }

  getOffset(element) {
    if (!element.getClientRects().length) {
      return { top: 0, left: 0 };
    }

    let rect = element.getBoundingClientRect();
    let win = element.ownerDocument.defaultView;
    return ({
      top: rect.top + win.pageYOffset,
      left: rect.left + win.pageXOffset
    });
  }

}

export default Parallaxr;
