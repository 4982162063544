class ReportSelection {

  constructor() {
    this.entriesChecked = 0;

    if (document.querySelector('.report_list')) {
      this.listContainerEl = document.querySelector('.report_list');
      this.actionsEl = this.listContainerEl.querySelector('.report_list__actions');
      this.checkboxEls = Array.from(this.listContainerEl.querySelectorAll('input[type="checkbox"]'));

      this.init();
    }
  }

  init() {
    this.checkboxEls.forEach((checkboxEl) => {
      if (checkboxEl.checked) {
        this.entriesChecked++;
      }
      this.showHideActions();

      checkboxEl.addEventListener('input', (e) => {
        this.checkboxInputChangeHandler(e)
      });
      checkboxEl.addEventListener('propertychange', (e) => {
        this.checkboxInputChangeHandler(e)
      });
    });
  }

  showHideActions() {
    if (this.entriesChecked > 1) {
      if (!this.actionsEl.classList.contains('report_list__actions--visible')) {
        this.actionsEl.classList.add('report_list__actions--visible');
      }
    } else {
      this.actionsEl.classList.remove('report_list__actions--visible');
    }
  }

  checkboxInputChangeHandler(e) {
    if (e.currentTarget.checked) {
      this.entriesChecked++;
		} else {
			this.entriesChecked--;
		}
    this.showHideActions();
  }

}

export default ReportSelection;
