class ScoreTable {

  constructor(el) {
    this.containerEl = el;
    this.toggleEl = el.querySelector('[data-score-table-role="toggle"]');
    this.tableEl = el.querySelector('[data-score-table-role="table"]');

    this.toggleShowEl = this.toggleEl.querySelector('[data-score-table-role="toggle-show"]');
    this.toggleHideEl = this.toggleEl.querySelector('[data-score-table-role="toggle-hide"]');

    this.visible = false;

    if (this.toggleEl && this.tableEl) {
      this.toggleEl.addEventListener('click', (e) => {
        e.preventDefault();
        this.toggleShowHide();
      })
    }
  }

  toggleShowHide() {
    this.visible = !this.visible;
    this.containerEl.dataset.scoreTableVisible = this.visible;
    this.toggleShowEl.style.display = (this.visible) ? 'none' : 'inline';
    this.toggleHideEl.style.display = (this.visible) ? 'inline' : 'none';
  }

}

export default ScoreTable;
