import FormValidator from './FormValidator';

class Test {

  constructor(activePart) {
    if (!document.getElementById('test')) {
      return false;
    }

    this.userRole = '';
    this.activePart = activePart;
    this.formEl = document.getElementById('test');
    this.validator = new FormValidator(this.formEl);

    // Handle role selection
    Array.from(document.querySelectorAll('input[name="role"]')).forEach((inputEl) => {
      // Listen to change event
      inputEl.addEventListener('change', (e) => {
        this.userRole = e.currentTarget.value;
      });
    });

    // Handle radio button checking using space button
    Array.from(document.querySelectorAll('input[type="radio"]')).forEach((inputEl) => {
      inputEl.addEventListener('keyup', (e) => {
        if (event.key === ' ' || event.keyCode === 32) {
          inputEl.click();
        }
      });
    });

    Array.from(document.querySelectorAll('.test__part button')).forEach((buttonEl) => {
      buttonEl.addEventListener('click', (e) => {
        e.preventDefault();
        this.submitCurrentPart();
      });
    });

    window.addEventListener('resize', (e) => {
      this.resetScroll();
      this.initScroll();
    });

    this.initScroll();
  }

  resetScroll() {
    window.removeEventListener('scroll', this.windowScrollHandler.bind(this));
    const questionsEl = document.querySelector('.test__part--active .likert_questions');
    if (questionsEl) {
      const categoryEl = questionsEl.querySelector('.likert_questions__category');
      const legendEl = questionsEl.querySelector('.likert_questions__legend');
      const listEl = questionsEl.querySelector('.likert_questions__question_list');
      categoryEl.style.left = '';
      categoryEl.style.width = '';
      legendEl.style.left = '';
      legendEl.style.width = '';
      categoryEl.style.height = '';
      legendEl.style.height = '';
      listEl.style.marginTop = '';
      categoryEl.classList.remove('likert_questions__category--fixed');
      legendEl.classList.remove('likert_questions__legend--fixed');
    }
  }

  initScroll() {
    if (document.querySelector('.test__part--active .likert_questions')) {
      this.initialActiveLegendOffset = document.querySelector('.test__part--active .likert_questions').getBoundingClientRect().top - document.body.getBoundingClientRect().top;

      window.addEventListener('scroll', this.windowScrollHandler.bind(this));
      this.windowScrollHandler();
    }
  }

  windowScrollHandler() {
    const questionsEl = document.querySelector('.test__part--active .likert_questions');
    if (questionsEl) {
      const categoryEl = questionsEl.querySelector('.likert_questions__category');
      const legendEl = questionsEl.querySelector('.likert_questions__legend');
      const listEl = questionsEl.querySelector('.likert_questions__question_list');

      if (getComputedStyle(legendEl, null).display !== 'none') {
        const bodyScrollTop = document.documentElement.scrollTop || document.body.scrollTop;

        if (bodyScrollTop > this.initialActiveLegendOffset) {
          const categoryElBoundingRect = categoryEl.getBoundingClientRect();
          const legendElBoundingRect = legendEl.getBoundingClientRect();
          const height = Math.max(legendEl.getBoundingClientRect().height, categoryEl.getBoundingClientRect().height);
          categoryEl.style.left = `${categoryEl.getBoundingClientRect().left}px`;
          categoryEl.style.width = `${categoryEl.getBoundingClientRect().width}px`;
          legendEl.style.left = `${legendEl.getBoundingClientRect().left}px`;
          legendEl.style.width = `${legendEl.getBoundingClientRect().width}px`;
          categoryEl.classList.add('likert_questions__category--fixed');
          legendEl.classList.add('likert_questions__legend--fixed');
          categoryEl.style.height = `${height}px`;
          legendEl.style.height = `${height}px`;
          listEl.style.marginTop = `${height}px`;
        } else {
          categoryEl.style.left = '';
          categoryEl.style.width = '';
          legendEl.style.left = '';
          legendEl.style.width = '';
          categoryEl.style.height = '';
          legendEl.style.height = '';
          listEl.style.marginTop = '';
          categoryEl.classList.remove('likert_questions__category--fixed');
          legendEl.classList.remove('likert_questions__legend--fixed');
        }
      } else {
        categoryEl.style.left = '';
        categoryEl.style.width = '';
        legendEl.style.left = '';
        legendEl.style.width = '';
        categoryEl.style.height = '';
        legendEl.style.height = '';
        listEl.style.marginTop = '';
        categoryEl.classList.remove('likert_questions__category--fixed');
        legendEl.classList.remove('likert_questions__legend--fixed');
      }
    }
  }

  submitCurrentPart() {
    let validation = {
      hasErrors: true
    };
    if (this.activePart === 'identification') {
      validation = this.validator.partIsValid(this.activePart);
    } else {
      validation = this.validator.partIsValid(this.activePart, this.userRole);
    }

    if (!validation.hasErrors) {
      const oldPart = this.activePart;
      switch (this.activePart) {
        case 'identification':
          this.activePart = 'part1';
          break;
        case 'part1':
          this.activePart = 'part2';
          break;
        case 'part2':
          this.activePart = null;
          this.submitTest();
          break;
        default:
          break;
      }
      this.resetScroll();
      document.querySelector(`.test__part--active`).classList.remove('test__part--active');
      if (this.activePart) {
        document.querySelector(`.test__part[data-part="${this.activePart}"][data-role="${this.userRole}"]`).classList.add('test__part--active');
        window.scrollTo(0, 0);
        this.initScroll();
      } else {
        window.scrollTo(0, 0);
      }
    } else {
      window.scrollTo(0, validation.errorPosition);
    }
  }

  submitTest() {
    const xhr = new XMLHttpRequest();
    const re = /(.+)\[(\d+)\]\[(\d+)\]\[(\d+)\]/;

    let json = Array.from(this.formEl.querySelectorAll('input, select, textarea'))
      .filter(element => element.name)
      .reduce((json, element) => {
        if (element.type === 'radio' && element.checked) {
          if (element.name === 'role') {
            json[element.name] = element.value;
          } else {
            const nameParts = re.exec(element.name);
            if (!json[nameParts[1]]) {
              json[nameParts[1]] = {};
            }
            if (!json[nameParts[1]][nameParts[2]]) {
              json[nameParts[1]][nameParts[2]] = {};
            }
            if (!json[nameParts[1]][nameParts[2]][nameParts[3]]) {
              json[nameParts[1]][nameParts[2]][nameParts[3]] = {};
            }
            json[nameParts[1]][nameParts[2]][nameParts[3]][nameParts[4]] = element.value;
          }
        } else if (element.type !== 'radio') {
          json[element.name] = (element.type === 'checkbox') ? ((element.checked) ? element.value : false) : element.value;
        }
        return json;
      }, {});

    xhr.addEventListener('load', (e) => {
      if (xhr.readyState == 4 && xhr.status == 200) {
        window.location = 'show-results.php';
      } else {
        //console.log('error submitting');
      }
    });
    xhr.open('POST', this.formEl.action);
    xhr.setRequestHeader('Accept', 'application/json; charset=utf-8');
	  xhr.setRequestHeader('Content-Type', 'application/json; charset=UTF-8');
    xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
    xhr.send(JSON.stringify(json));

    window.scrollTo(0, 0);
    document.querySelector('[data-loader]').style.display = 'block';
  }

}

export default Test;
